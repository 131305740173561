import rootReducer from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';

const middlewares = [];

const store = configureStore({
	reducer: rootReducer(),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}).concat(middlewares)
})

store.asyncReducers = {};

export default store