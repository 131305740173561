import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: 'auth',
		method: 'post',
		data: data
	});
}

AuthService.refreshToken = function (data) {
	return fetch({
		url: 'auth/refresh-token',
		method: 'post',
		data: data
	});
}

AuthService.register = function (data, captchaValue) {
	const { email, password, confirm, firstName, lastName, termsOfUse} = data
	return fetch({
		url: '/users/registration',
		method: 'post',
		data: {
			"firstName": firstName,
			"lastName": lastName,
			"email": email,
			"password": password,
			"repeatedPassword": confirm,
			"username": firstName + '-' + lastName,
			"termsOfUse": termsOfUse,
			"association": {
				"name": `${firstName}-${lastName}-association`
			},
			"captchaValue": captchaValue.length > 1
		}
	})
}

AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}

export default AuthService;