import { useEffect } from 'react';

const addBodyClass = className => document.body.classList.add(className);
const removeBodyClass = className => document.body.classList.remove(className);

export default function useBodyClass(className, add = true) {
	useEffect(() => {
		if(add) {
			className instanceof Array ? className.map(addBodyClass) : addBodyClass(className);
		} else {
			className instanceof Array ? className.map(removeBodyClass) : removeBodyClass(className);
		}
	}, [add, className]);
}