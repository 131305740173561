import React from 'react'
import { Navigate } from 'react-router-dom'
import { getUser } from "../services/CookieService";

const AppRoles = ({ requiredRole, children }) => {
    const user = getUser();

    if (user && requiredRole && user.roles && !user.roles.includes(requiredRole)) {
        return <Navigate to="/app/error/accessDenied" replace />;
    }

    return children;
}

export default AppRoles;