import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';

export const currentLocation = window.location.origin;

const API_URLS = {
	'https://testapp.talent-edu.eu': 'https://testapi.talent-edu.eu/api',
	'https://app.talent-edu.eu': 'https://api.talent-edu.eu/api',
	'default': 'http://local.e-udruge-backend.com/api',
};

const SITE_KEYS = {
	'https://testapp.talent-edu.eu': {
		SITE_KEY: '6Ld20CAqAAAAAC3VAhK1UjC6DXBZ19PGMZqn--l_',
		SECRET_KEY: '6Ld20CAqAAAAAEuuBecYNZqqyhEA_YmFnKP-1BV9'
	},
	'https://app.talent-edu.eu': {
		SITE_KEY: '6Leq0SAqAAAAAJeiX3-rQ076_FN4Uuy7azJvwcaj',
		SECRET_KEY: '6Leq0SAqAAAAAJdMYLc0DlynRunmlT6w21mpKQgw'
	},
	'default': {
		SITE_KEY: '6LfB5BgqAAAAALocalDevSiteKeyExample',
		SECRET_KEY: '6LfB5BgqAAAAALocalDevSecretKeyExample'
	}
};


export const APP_NAME = 'Talent';
export const DateFormat = 'DD.MM.YYYY.';
export const DateFormatWithTime = 'DD.MM.Y. HH:mm:ss';

export const API_BASE_URL = API_URLS[currentLocation] || API_URLS['default'];
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`;
export const UNAUTHENTICATED_ENTRY = '/login'
export const REACT_APP_GOOGLE_MAPS_API_KEY="AIzaSyBroEjodAVI0htzJry76vzS5HxuWetTSWw"

const siteConfig = SITE_KEYS[currentLocation] || SITE_KEYS['default'];
export const REACT_APP_SITE_KEY = siteConfig.SITE_KEY;
export const REACT_APP_SECRET_KEY = siteConfig.SECRET_KEY;

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'hr',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#224368',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false
};
