import SlackErrorService from "../services/SlackErrorService";

const handleErrorApi = async (error) => {
    return SlackErrorService.sendError({
        name: error.error.message,
        fileName: error.filename,
        stack: error.error.stack,
        message: error.message
    });
}

export {
    handleErrorApi
};