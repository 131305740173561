import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: `${AUTH_PREFIX_PATH}/reset-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password/reset-password.js')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/pages/dashboards/default')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'dashboard.reportProblem',
        path: `${APP_PREFIX_PATH}/dashboards/reportProblem`,
        component: React.lazy(() => import('views/app-views/pages/dashboards/report-problem/Index')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'edit.profile',
        path: `${APP_PREFIX_PATH}/dashboard/edit-profile/:id`,
        component: React.lazy(() => import('views/app-views/pages/dashboards/edit-profile/edit.js')),
        requiredRole: 'ROLE_USER'
    },
    {
        key: 'codeBook.test',
        path: `${APP_PREFIX_PATH}/codeBook/test`,
        component: React.lazy(() => import('views/app-views/pages/codeBook/test')),
    },
    {
        key: 'codebook_category_of_good_practice',
        path: `${APP_PREFIX_PATH}/admin/categoryOfGoodPractice`,
        component: React.lazy(() => import('views/app-views/pages/admin/categoryOfGoodPractice/CategoryOfGoodPractice')),
        requiredRole: 'ROLE_ADMIN'
    },
    {
        key: 'codebook_types_of_support_offered',
        path: `${APP_PREFIX_PATH}/admin/typesOfSupportOffered`,
        component: React.lazy(() => import('views/app-views/pages/admin/typesOfSupportOffered/TypesOfSupportOffered')),
        requiredRole: 'ROLE_ADMIN'
    },
    {
        key: 'error.accessDenied',
        path: `${APP_PREFIX_PATH}/error/accessDenied`,
        component: React.lazy(() => import('views/app-views/pages/error/accessDenied')),
    },
    {
        key: 'error.error-page-1',
        path: `${APP_PREFIX_PATH}/error/error`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1/index.js')),
    },
    {
        key: 'admin.userRoles',
        path: `${APP_PREFIX_PATH}/admin/userRoles`,
        component: React.lazy(() => import('views/app-views/pages/admin/roles/userRoles')),
        requiredRole: 'ROLE_ADMIN',
    },
    {
        key: 'admin.role.programs',
        path: `${APP_PREFIX_PATH}/admin/role/programs/:id`,
        component: React.lazy(() => import('views/app-views/pages/admin/roles/programs/ProgramsOnRole.js')),
        requiredRole: 'ROLE_ADMIN',
    },
    {
        key: 'admin.tests',
        path: `${APP_PREFIX_PATH}/admin/tests`,
        component: React.lazy(() => import('views/app-views/pages/admin/tests/Tests')),
        requiredRole: 'ROLE_ADMIN',
    },
    {
        key: 'admin.fieldOfExpertize',
        path: `${APP_PREFIX_PATH}/admin/fieldOfExpertize`,
        component: React.lazy(() => import('views/app-views/pages/admin/fieldsOfExpertizes/FieldOfExpertize')),
        requiredRole: 'ROLE_ADMIN',
    },
    {
        key: 'admin.sport',
        path: `${APP_PREFIX_PATH}/admin/sports`,
        component: React.lazy(() => import('views/app-views/pages/admin/sport/Sport')),
        requiredRole: 'ROLE_ADMIN',
    },
    {
        key: 'admin.documents',
        path: `${APP_PREFIX_PATH}/admin/documentsCodebook`,
        component: React.lazy(() => import('views/app-views/pages/admin/documents/Documents')),
        requiredRole: 'ROLE_ADMIN',
    },
    {
        key: 'admin.serviceProvider',
        path: `${APP_PREFIX_PATH}/admin/serviceProvider`,
        component: React.lazy(() => import('views/app-views/pages/admin/serviceProvider/ServiceProvider')),
        requiredRole: 'ROLE_ADMIN',
    },
    {
        key: 'admin.goodPractice',
        path: `${APP_PREFIX_PATH}/admin/goodPractice`,
        component: React.lazy(() => import('views/app-views/pages/admin/goodPractice/GoodPractice')),
        requiredRole: 'ROLE_ADMIN',
    },
    {
        key: 'admin.users',
        path: `${APP_PREFIX_PATH}/admin/users`,
        component: React.lazy(() => import('views/app-views/pages/admin/users/Users')),
        requiredRole: 'ROLE_ADMIN',
    },
    {
        key: 'admin.testingCategory',
        path: `${APP_PREFIX_PATH}/admin/testingCategory`,
        component: React.lazy(() => import('views/app-views/pages/admin/testingCategory/testingCategory')),
        requiredRole: 'ROLE_ADMIN',
    },
    {
        key: 'edit.addUser',
        path: `${APP_PREFIX_PATH}/admin/add-user`,
        component: React.lazy(() => import('views/app-views/pages/dashboards/edit-profile/add.js')),
        requiredRole: 'ROLE_ADMIN',
    },
    {
        key: 'potential.talents.index',
        path: `${APP_PREFIX_PATH}/potentialTalents/index`,
        component: React.lazy(() => import('views/app-views/pages/potentialTalents/Index')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'potential.talents.add',
        path: `${APP_PREFIX_PATH}/potentialTalents/add`,
        component: React.lazy(() => import('views/app-views/pages/potentialTalents/action/Add')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'potential.talents.update',
        path: `${APP_PREFIX_PATH}/potentialTalents/update/:id`,
        component: React.lazy(() => import('views/app-views/pages/potentialTalents/action/Update')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'services.providers.index',
        path: `${APP_PREFIX_PATH}/serviceProviders/index`,
        component: React.lazy(() => import('views/app-views/pages/serviceProviders/Index')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'services.providers.add',
        path: `${APP_PREFIX_PATH}/serviceProviders/add`,
        component: React.lazy(() => import('views/app-views/pages/serviceProviders/action/Add')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'services.providers.view',
        path: `${APP_PREFIX_PATH}/serviceProviders/view/:id`,
        component: React.lazy(() => import('views/app-views/pages/serviceProviders/view/View')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'services.providers.update',
        path: `${APP_PREFIX_PATH}/serviceProviders/update/:id`,
        component: React.lazy(() => import('views/app-views/pages/serviceProviders/action/Update')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'good.practice.view',
        path: `${APP_PREFIX_PATH}/goodPractice`,
        component: React.lazy(() => import('views/app-views/pages/goodPractice/Index')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'good.practice.create',
        path: `${APP_PREFIX_PATH}/goodPractice/create`,
        component: React.lazy(() => import('views/app-views/pages/goodPractice/action/Add')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'good.practice.create',
        path: `${APP_PREFIX_PATH}/goodPractice/update/:id`,
        component: React.lazy(() => import('views/app-views/pages/goodPractice/action/Update')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'good.practice.details',
        path: `${APP_PREFIX_PATH}/goodPractice/details/:id`,
        component: React.lazy(() => import('views/app-views/pages/goodPractice/view/View')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'tests.index',
        path: `${APP_PREFIX_PATH}/tests/index`,
        component: React.lazy(() => import('views/app-views/pages/tests/Index')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'tests.add',
        path: `${APP_PREFIX_PATH}/tests/add`,
        component: React.lazy(() => import('views/app-views/pages/tests/action/Add')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'tests.update',
        path: `${APP_PREFIX_PATH}/tests/update/:id`,
        component: React.lazy(() => import('views/app-views/pages/tests/action/Update')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'tests.details',
        path: `${APP_PREFIX_PATH}/tests/details/:id`,
        component: React.lazy(() => import('views/app-views/pages/tests/view/View')),
        requiredRole: 'ROLE_USER',
    },
    {
        key: 'help',
        path: `${APP_PREFIX_PATH}/dashboard/help`,
        component: React.lazy(() => import('views/app-views/pages/dashboards/help/index')),
        requiredRole: 'ROLE_USER',
    },
]