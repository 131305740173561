const antdBs_BA = {
    locale: 'bs',
    Pagination: {
        items_per_page: 'stavki po stranici',
        jump_to: 'Idi na',
        jump_to_confirm: 'potvrdi',
        page: '',
        prev_page: 'Prethodna stranica',
        next_page: 'Sljedeća stranica',
        prev_5: 'Prethodnih 5 stranica',
        next_5: 'Sljedećih 5 stranica',
        prev_3: 'Prethodne 3 stranice',
        next_3: 'Sljedeće 3 stranice',
    },
    DatePicker: {
        lang: {
            locale: 'bs',
            placeholder: 'Izaberite datum',
            rangePlaceholder: ['Početni datum', 'Krajnji datum'],
            today: 'Danas',
            now: 'Sada',
            backToToday: 'Nazad na danas',
            ok: 'OK',
            clear: 'Obriši',
            month: 'Mjesec',
            year: 'Godina',
            timeSelect: 'Izaberite vrijeme',
            dateSelect: 'Izaberite datum',
            weekSelect: 'Izaberite sedmicu',
            monthSelect: 'Izaberite mjesec',
            yearSelect: 'Izaberite godinu',
            decadeSelect: 'Izaberite deceniju',
            yearFormat: 'YYYY',
            dateFormat: 'D.M.YYYY',
            dayFormat: 'D',
            dateTimeFormat: 'D.M.YYYY HH:mm:ss',
            monthFormat: 'MMMM',
            monthBeforeYear: true,
            previousMonth: 'Prethodni mjesec (PageUp)',
            nextMonth: 'Sljedeći mjesec (PageDown)',
            previousYear: 'Prošla godina (Control + left)',
            nextYear: 'Sljedeća godina (Control + right)',
            previousDecade: 'Prethodna decenija',
            nextDecade: 'Sljedeća decenija',
            previousCentury: 'Prošli vijek',
            nextCentury: 'Sljedeći vijek',
        },
        timePickerLocale: {
            placeholder: 'Izaberite vrijeme',
        },
    },
    TimePicker: {
        placeholder: 'Izaberite vrijeme',
    },
    Calendar: {
        lang: {
            locale: 'bs',
            placeholder: 'Izaberite datum',
            rangePlaceholder: ['Početni datum', 'Krajnji datum'],
            today: 'Danas',
            now: 'Sada',
            backToToday: 'Nazad na danas',
            ok: 'OK',
            clear: 'Obriši',
            month: 'Mjesec',
            year: 'Godina',
            timeSelect: 'Izaberite vrijeme',
            dateSelect: 'Izaberite datum',
            weekSelect: 'Izaberite sedmicu',
            monthSelect: 'Izaberite mjesec',
            yearSelect: 'Izaberite godinu',
            decadeSelect: 'Izaberite deceniju',
            yearFormat: 'YYYY',
            dateFormat: 'D.M.YYYY',
            dayFormat: 'D',
            dateTimeFormat: 'D.M.YYYY HH:mm:ss',
            monthFormat: 'MMMM',
            monthBeforeYear: true,
            previousMonth: 'Prethodni mjesec (PageUp)',
            nextMonth: 'Sljedeći mjesec (PageDown)',
            previousYear: 'Prošla godina (Control + left)',
            nextYear: 'Sljedeća godina (Control + right)',
            previousDecade: 'Prethodna decenija',
            nextDecade: 'Sljedeća decenija',
            previousCentury: 'Prošli vijek',
            nextCentury: 'Sljedeći vijek',
        },
        timePickerLocale: {
            placeholder: 'Izaberite vrijeme',
        },
    },
    global: {
        placeholder: 'Izaberite',
    },
    Table: {
        filterTitle: 'Filter meni',
        filterConfirm: 'OK',
        filterReset: 'Resetuj',
        selectAll: 'Izaberi trenutnu stranicu',
        selectInvert: 'Invertuj trenutnu stranicu',
        selectionAll: 'Izaberi sve podatke',
        sortTitle: 'Sortiraj',
    },
    Modal: {
        okText: 'OK',
        cancelText: 'Otkaži',
        justOkText: 'Samo OK',
    },
    Popconfirm: {
        okText: 'OK',
        cancelText: 'Otkaži',
    },
    Transfer: {
        titles: ['', ''],
        searchPlaceholder: 'Pretraži ovdje',
        itemUnit: 'stavka',
        itemsUnit: 'stavki',
        remove: 'Ukloni',
        selectCurrent: 'Izaberi trenutnu stranicu',
        removeCurrent: 'Ukloni trenutnu stranicu',
        selectAll: 'Izaberi sve podatke',
        removeAll: 'Ukloni sve podatke',
        selectInvert: 'Invertuj trenutnu stranicu',
    },
    Upload: {
        uploading: 'Učitavanje...',
        removeFile: 'Ukloni datoteku',
        uploadError: 'Greška pri učitavanju',
        previewFile: 'Pregledaj datoteku',
        downloadFile: 'Preuzmi datoteku',
    },
    Empty: {
        description: 'Nema podataka',
    },
    Icon: {
        icon: 'ikona',
    },
    Text: {
        edit: 'Uredi',
        copy: 'Kopiraj',
        copied: 'Kopirano',
        expand: 'Proširi',
    },
    PageHeader: {
        back: 'Nazad',
    },
};

export default antdBs_BA;
