import fetch from 'auth/FetchInterceptor'

const SlackErrorService = {}

SlackErrorService.sendError = function (data) {
    return fetch({
        url: 'send-slack-error-notification',
        method: 'post',
        data: data
    });
}

export default SlackErrorService;