import antdEnUS from 'antd/es/locale/en_US';
import antdHrHR from 'antd/es/locale/hr_HR';
import antdIt_IT from 'antd/es/locale/it_IT';
import antdSr_RS from 'antd/es/locale/sr_RS';
import antdEl_GR from 'antd/es/locale/el_GR';
import antdBs_BA from './customLang/antdBs_BA';
import en from './locales/en_US.json';
import hr from './locales/hr_HR.json';
import it from './locales/it_IT.json';
import sr from './locales/sr_RS.json';
import el from './locales/el_GR.json';
import bs from './locales/bs_BA.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { THEME_CONFIG } from 'configs/AppConfig';

export const resources = {
    en: {
        translation: en,
        antd: antdEnUS
    },
    hr: {
        translation: hr,
        antd: antdHrHR
    },
    it: {
        translation: it,
        antd: antdIt_IT
    },
    sr: {
        translation: sr,
        antd: antdSr_RS
    },
    el: {
        translation: el,
        antd: antdEl_GR
    },
    bs: {
        translation: bs,
        antd: antdBs_BA
    }
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: THEME_CONFIG.locale,
    lng: THEME_CONFIG.locale,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
